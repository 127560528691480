import React, {useState} from "react";
import "../../assets/scss/styles.scss"

import DarkFooter from "../../components/Footers/DarkFooter.js";
import Signature from "../signaturejs.js";
import evides from '../../assets/img/logos/evides.png'
import essex from '../../assets/img/logos/essex.png'
import demcon from '../../assets/img/logos/demcon.png'
import twi from '../../assets/img/logos/THL_logo_main.png'
import barbantwater from '../../assets/img/logos/brabantwater.svg'
import bendabl from '../../assets/img/logos/bendabl.svg'
import alsymex from '../../assets/img/logos/alsymex.png'
import vitens from '../../assets/img/logos/vitens.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareXmark } from '@fortawesome/free-solid-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';






import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "../../components/Headers/LandingPageHeader.js";
import Modal from 'react-bootstrap/Modal';


function Partners() {
  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [twiShow, setTwiShow] = useState(false);
  const [bendablShow, setBendablShow] = useState(false);
  const [vitensShow, setVitensShow] = useState(false);
  const [alsymexShow, setAlsymexShow] = useState(false);
  const [barbantShow, setBarbantShow] = useState(false);
  const [demconShow, setDemconShow] = useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        
 
        <section className="section section-about-us" id='partners'>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title" style={{color:'#206a72',marginTop:'50px'}}>Partners</h2>
                <section class="section section-default mt-none mb-none">
<div class="container">

<strong>
<div class="row">

<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={demcon} onClick={() => setDemconShow(true)}/>
<Modal 
        show={demconShow}
        onHide={() => setDemconShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#07353d'}}>
        <Modal.Title>
        <h5 className="title" style={{color:'#dadada'}}>DEMCON 
        <span> <a target="_blank" href="https://demcon.com/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#206a72',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> 
        </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setDemconShow(false)}/>
        </Modal.Header>
        <Modal.Body>Demcon, established in 1993 in Enschede, NL,  was created as a result of the passion of its founders for solving challenging technological and social problems. Currently, 
          Demcon consists of 25 different companies or entities and employs 1000 people in 9 locations across 4 countries.
</Modal.Body>
<Modal.Body>
Demcon, supported by a sophisticated quality system (in accordance with ISO 9001, ISO 13485 and ISO 45001), develops high-quality solutions, 
innovative and complex systems and products in order to tackle social challenges in the areas of aerospace, 
agriculture & food, defence & security, energy, high-tech systems & materials, life sciences & health, smart industry and water & maritime.
</Modal.Body>
  <Modal.Body>In a society faced with major challenges, we carry out projects that have a positive impact on people and the world they live in. In addition to technological innovation, 
    we also devote our effort to promoting entrepreneurship and 
    investing in talent and education.
</Modal.Body>
<Modal.Body>Demcon positions itself as a developer and producer of high-end systems and products. To increase our impact in different social domains, 
  it aims to continue to grow and to intensify and broaden our cooperation with organizations, 
  knowledge institutes and government entities – referring to this as ‘creating shared value’.
</Modal.Body>
<Modal.Body>Within the TUBERS project, Demcon will develop and improve a snake-like robot that can travel through the water supply network and can detect leakages and congestions within that network.
</Modal.Body>
      </Modal>
</div>
</div>
<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={twi} onClick={() => setTwiShow(true)}/>
<Modal 
        show={twiShow}
        onHide={() => setTwiShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#07353d'}}>
        <Modal.Title><h5 className="title" style={{color:'#dadada'}}>THL <span> <a target="_blank" href="https://www.twi-hellas.com/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#206a72',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setTwiShow(false)}/>
        </Modal.Header>
        <Modal.Body><b>TECH HIVE LABS (THL)</b> is a European centre of excellence in Robotics and AI, where bold ideas and applied research turn into industrial solutions with real impact. We blend the curiosity of a lab with the precision of a business leader to drive technology engineering and transform experimentation into real-world capability, empowering entrepreneurs and enterprises to innovate with confidence and deliver measurable results.
</Modal.Body>
  <Modal.Body>Our motto is <b>"Serious Playground. Real Impact."</b>, which is also our way of working. We offer diverse engagement models, from collaborative projects and knowledge exchange to engineering consulting and technology transfer, to suit the specific needs of research collaborators and industry partners and ensure that every endeavour drives real results.
</Modal.Body>


      </Modal>
</div>
</div>
<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={alsymex} onClick={() => setAlsymexShow(true)}/>
<Modal 
        show={alsymexShow}
        onHide={() => setAlsymexShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#07353d'}}>
        <Modal.Title><h5 className="title" style={{color:'#dadada'}}>Alsymex<span> <a target="_blank" href="https://www.alsymex-alcen.com/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#206a72',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setAlsymexShow(false)}/>
        </Modal.Header>
        <Modal.Body>ALSYMEX is a company of the French industrial group ALCEN.
</Modal.Body>
<Modal.Body>
ALSYMEX is specialized in the design and manufacture of complex systems for clients involved in Defense & Security, Energy, Aeronautics & Space and Large Scientific Instruments. 
ALSYMEX is actively contributing to the supply of critical components for the fusion project of ITER.
</Modal.Body>
  <Modal.Body>ALSYMEX has recently launched an initiative to explore opportunities in the integration of smart automation and robotics processes dedicated to dexterous manipulation 
    and inspection & maintenance missions of critical assets in challenging environments.
</Modal.Body>
      </Modal>
</div>
</div>
<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={bendabl} onClick={() => setBendablShow(true)}/>
<Modal 
        show={bendablShow}
        onHide={() => setBendablShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#07353d'}}>
        <Modal.Title><h5 className="title" style={{color:'#dadada'}}>bendabl<span> <a target="_blank" href="https://bendabl.com/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#206a72',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setBendablShow(false)}/>
        </Modal.Header>
        <Modal.Body>Bendabl is a high-tech SME based in Greece. The Bendabl team is composed of engineers with a passion for soft robotics and pneumatically actuated systems with more than 10 years 
          of collective experience in the field. We believe that the future belongs to robots that can reach the pinnacle of adaptability, the very change of their physical properties through mechanically 
          programmable metamaterials. Having accumulated deep expertise in pneumatically-actuated mechanisms and soft structure manufacturing using inherently compliant materials, we aspire to be the pioneers 
          of this new generation of robots. To push the boundaries of their capability, to broaden the range of applications they can help with, and, most importantly, to flatten the learning curve of both developing 
          and deploying them.
</Modal.Body>
<Modal.Body>
In the TUBERS project, Bendabl will be responsible for developing the soft robotic crawling robot able to navigate piping systems using a worm-like motion. This robot will be able to detect leaks and isolate parts of the piping 
system for maintenance and repair without disrupting the medium flow.
</Modal.Body>
      </Modal>
</div>
</div>
<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={essex} onClick={() => setSmShow(true)}/>
<Modal 
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#07353d'}}>
        <Modal.Title><h5 className="title" style={{color:'#dadada'}}>Essex<span> <a target="_blank" href="https://www.essex.ac.uk/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#206a72',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setSmShow(false)}/>
        </Modal.Header>
        <Modal.Body>The University of Essex (UESS) is gold-rated in the Teaching Excellence Framework 2017, and we're in the top 25 for research quality in The Times and Sunday Times Good University Guide. 
          We’re committed to excellence in research for the benefit of individuals, communities,
          and the industry, and we provide extensive open access to our research repository.
</Modal.Body>
<Modal.Body>The Essex Artificial Intelligence Innovation Centre brings together the research skills, expertise and technologies across our Faculty of Humanities, Science and Health, and Social Sciences to 
  address the grand challenges facing citizens, workers, and all those technologists in sectors undergoing digital transformation to create new – or modify existing - business processes, culture, and customer 
  experiences to meet changing business and market requirements. We have the ambition of developing a sustainable funding model for our research fit for the rigours of the emerging UK research environment. 
  We reach beyond the confines of academia as we have a clear mandate to work with business partners in the UK and overseas. We’re different because we bring a commercial mindset to our work. We start with
  and are responsive to business needs. We work hard to make sure they are getting the outputs they need when they need them. We’re not bound by tradition and offer fresh perspectives and innovation in all we do.

</Modal.Body>
      </Modal>
</div>
</div>
<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={vitens} onClick={() => setVitensShow(true)}/>
<Modal 
        show={vitensShow}
        onHide={() => setVitensShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#07353d'}}>
        <Modal.Title><h5 className="title" style={{color:'#dadada'}}>Vitens<span> <a target="_blank" href="https://www.vitens.nl/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#206a72',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setVitensShow(false)}/>
        </Modal.Header>
        <Modal.Body>Vitens is the largest drinking water company in The Netherlands, serving 5.7 million inhabitants in 5 provinces. With 1700 employees, we ensure that groundwater 
          is extracted from the best sources, purified and distributed, 
          making use of 50.000 km of infrastructure. Vitens produces more than 396 Mm³ drinking water using 96 production sites.
</Modal.Body>
<Modal.Body>
Participation in the Tubers project is important to gain new technologies for the condition assessment of our mains in an affordable approach.
</Modal.Body>
      </Modal>
</div>
</div>
<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={evides} onClick={() => setLgShow(true)}/>
<Modal centered show={lgShow}
        onHide={() => setLgShow(false)}>
        <Modal.Header style={{backgroundColor:'#07353d'}}>
        
          <Modal.Title><h5 className="title" style={{color:'#dadada'}}>Evides<span> <a target="_blank" href="https://www.evides.nl/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#206a72',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setLgShow(false)}/>
        </Modal.Header>
        <Modal.Body>With our public water supply task, we are at the heart of society and continuously working on making our business operations more sustainable. 
          We purify and supply water in an environmentally friendly way. Our drinking water is prepared 80% from the river Maas, 16% from groundwater and 4% from dune water. 
          In the drinking water segment, measured by connections, sales and turnover, we are in second place in the Netherlands.
</Modal.Body>
<Modal.Body>Of the surface water we use as a source for industrial water production, we extract 47% from Lake Brielse, 
  40% from the river Maas, 7% from the Elbe and 6% from runoff Belgian polder water. We also use approximately 16 million cubic meters of industrial water per year produced from wastewater. 
  In the industrial water segment, we are one of the strongest players in Northwestern Europe. We also purify wastewater from The Hague/Delft, Schiphol and business complexes in the Sloe area (Vlissingen) and Delfzijl.
  Our distribution network consists of 14,000 km of the network throughout our supply area.
</Modal.Body>
<Modal.Body>Evides Waterbedrijf participates in the Tubers project because we want to improve our professional asset management approach and wish to work with condition-based maintenance of our assets. 
  Advanced inspection and repair techniques will add value, cost-efficient repairs, lower non-revenue water and higher customer satisfaction.
</Modal.Body>
      </Modal>
</div>
</div>
<div class="col-sm-6 col-md-4 col-lg-3">
<div class="square-holder">
<img alt="" src={barbantwater} onClick={() => setBarbantShow(true)}/>
<Modal 
        show={barbantShow}
        onHide={() => setBarbantShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header style={{backgroundColor:'#07353d'}}>
        <Modal.Title><h5 className="title" style={{color:'#dadada'}}>Brabant Water<span> <a href="https://www.brabantwater.nl/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#206a72',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer',float:'right'}}/></a></span>
        </h5> </Modal.Title>
          <FontAwesomeIcon variant="secondary" icon={faSquareXmark} style={{color:'#dadada',fontSize:'20px',cursor:'pointer',float:'left'}} onClick={() => setBarbantShow(false)}/>
        </Modal.Header>
        <Modal.Body>Brabant Water is a sustainable drinking water company that supplies drinking and industrial water, day and night. 
          The drinking water is distributed to 2.6 million inhabitants and companies in the province of Brabant in the Netherlands. With 800 employees, we ensure that groundwater is extracted 
          from the best sources, after which the water is purified and distributed. We do this with pride, craftsmanship and care for people, nature and the environment with the aim of securing 
          our water sources for the future.

</Modal.Body>
<Modal.Body>
Brabant Water produces more than 190 Mm³ drinking water and 9 Mm³ industrial water from 35 water extraction areas at 29 production locations. The distribution network consists of 1,700 km of transport pipelines, 
16,500 km of distribution pipelines and more than 1 million household connections and water meters.
</Modal.Body>
<Modal.Body>
Participation in the Tubers project is important to Brabant Water because we want to professionalize in asset management and grow from failure based to condition-based maintenance of our distribution assets. 
An autonomous inspection robot which inspects our pipes internally will be of great value to achieve this.
</Modal.Body>
      </Modal>
</div>
</div>
</div>
</strong>
</div>
</section>
              </Col>
            </Row>
            </Container>
            </section>
           
       
        <DarkFooter />
       
      </div>
    </>
  );
}

export default Partners;
