import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";


import blogimg1 from '../assets/img/TUBERS Online Workshop.png'

import pdffile from './pdffile.pdf'
import icon from '../assets/img/pdficon.svg'

import ExamplesNavbar from './Navbars/ExamplesNavbar';
import DarkFooter from './Footers/DarkFooter';

const Article13 = () => {
  

  return (
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

          <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#07353d'}} className="post-title pt-3">
                  TUBERS Project’s First Workshop: Introducing Our Water Infrastructure Solution      
                  </h3>
                  <div class="postcard__subtitle">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 09/10/2024
					</time>
				</div>
        <br/>
                 
                </header>
                <div className="post-description">
                <img style={{float:'left',margin:' 0px  20px 20px 0px'}} src={blogimg1} title=""  width="340" height="291" />
               <p style={{textAlign:'justify'}}>In October 2024, the EU-funded TUBERS project held an introductory online workshop on October 1st. The event brought together researchers, engineers, and industry professionals to discuss advancements in robotic inspection and repair technologies for water distribution pipelines. It provided an opportunity to present the project’s vision and mission, exchange opinions, answer questions, and receive initial feedback regarding the robotic system’s potential contribution to the water management industry.
</p>
<p style={{textAlign:'justify'}}>
Water distribution networks across Europe face substantial losses—up to 20%—due to undetected leaks and pipeline deterioration. The TUBERS project, funded by the Horizon Europe Programme, seeks to address this issue by developing an innovative robotic ecosystem designed for continuous pipeline inspection and in-situ repair. This system integrates cutting-edge soft robotics, artificial intelligence, and autonomous navigation to minimise maintenance costs and reduce environmental impact.</p>
<p style={{textAlign:'justify'}}>
The online workshop provided attendees with an overview look at the project's progress, focusing on:
</p>
<ul>
  <li><b>Resident Snake-Like Robots</b> – Experts demonstrated how autonomous, flexible robotic systems can navigate complex pipeline networks for real-time inspection.</li>
  <li><b>Modular Soft Robotics</b> – Researchers showcased novel soft robotic platforms capable of executing targeted repairs without requiring extensive excavation.</li>
  <li><b>AI-Driven Decision Support</b> – The integration of artificial intelligence in monitoring and predictive maintenance was explored, emphasising its potential to enhance water network efficiency.</li>
</ul>
<p style={{textAlign:'justify'}}>
The workshop presentations covered the current challenges in water infrastructure asset management and the project's mission and ambitions, with contributions from an expert from <a id='hyperlink' href='https://www.evides.nl/<'>Evides</a> and Joël Vanden Bosch from <a id='hyperlink' href='https://www.alsymex-alcen.com/'>ALSYMEX</a>. The event also featured an interactive discussion, where participants from academia and the industry exchanged insights on real-world applications and future improvements. Key takeaways included the importance of cross-sector collaboration and the role of smart technologies in modernising water infrastructure.
</p>
<p style={{textAlign:'justify'}}>With its commitment to pioneering sustainable solutions, the TUBERS project continues to push the boundaries of robotic pipeline maintenance. Following this successful workshop, the team aims to further refine its robotic platforms and prepare for the second workshop to come next year!</p>

              



                
                </div>
                
                
                </div>
               
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
  )
}

export default Article13