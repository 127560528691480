import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";


import blogimg1 from '../assets/img/image45.jpg'

import pdffile from './pdffile.pdf'
import icon from '../assets/img/pdficon.svg'

import ExamplesNavbar from './Navbars/ExamplesNavbar';
import DarkFooter from './Footers/DarkFooter';

const Article14 = () => {
  

  return (
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

          <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#07353d'}} className="post-title pt-3">
                  TUBERS at the Green Deal Cluster Workshop on AI, Data, and Robotics    
                  </h3>
                  <div class="postcard__subtitle">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
                 20/02/2025
					</time>
				</div>
        <br/>
                 
                </header>
                <div className="post-description">
                <img style={{float:'left',margin:' 0px  20px 20px 0px'}} src={blogimg1} title=""  width="540" height="291" />
               <p style={{textAlign:'justify'}}>
               The Green Deal Cluster Workshop in Brussels recently brought us together four other prominent
EU-funded projects — <a id='hyperlink'  href='https://alchimia-project.eu/'>ALCHIMIA</a>, <a id='hyperlink'  href='https://clarus-project.eu/'>CLARUS</a>, <a  id='hyperlink' href='https://www.wastewater.ai/'>DARROW</a> and <a  id='hyperlink' href='https://reclaim-box.eu/'>RECLAIM </a>— for an insightful and
dynamic exchange on the role of AI, Data, and Robotics in driving green optimisation across
industries.</p>
<p style={{textAlign:'justify'}}>
During this workshop, as part of the <a id='hyperlink' href='https://future-ready.zohobackstage.eu/On-Demand-Solutions-AI-Data-Robotics'>Future-Ready On-Demand Solutions with AI, Data, and
Robotics</a> event, industry experts, researchers, and policymakers gathered to discuss innovative,
future-ready solutions to ensure European industries' sustainability and competitiveness. The
TUBERS consortium, known for its cutting-edge work on robotic solutions for pipeline inspection
and repair, contributed to the discussion on how advanced robotics can enhance sustainability
in critical infrastructure sectors such as water networks.</p>
<p style={{textAlign:'justify'}}>
The workshop covered a broad spectrum of sectors, with ALCHIMIA presenting breakthroughs
in metalworking, CLARUS exploring sustainable practices in the food production industry, and
RECLAIM focusing on solid waste material recovery. Meanwhile, DARROW showcased
innovations in wastewater treatment, demonstrating the significant potential of AI and robotics in
environmental protection.</p>
<p style={{textAlign:'justify'}}>One of the event's core themes was the central question: Can European industries remain
competitive while maintaining their environmental and social responsibilities? The discussions
emphasised that sustainability and competitiveness are not mutually exclusive. By embracing
advanced technologies like AI and robotics, industries can drive green optimisation and achieve
long-term viability.</p>
<p style={{textAlign:'justify'}}>The TUBERS team represented by Nikos Panagopoulos <a  id='hyperlink' href='https://www.linkedin.com/company/tech-hive-labs/'>(THL)</a> and Joël vanden Bosch
<a  id='hyperlink' href='https://www.linkedin.com/company/alsymex-alcen/'>(Alsymex)</a> highlighted the importance of scalable, modular robotic systems for pipeline
maintenance, which not only improve efficiency but also reduce environmental impact by
extending the lifespan of infrastructure and minimising disruptions. This aligns perfectly with the
broader goal of ensuring a green, sustainable future for industries across Europe.</p>
<p style={{textAlign:'justify'}}>The “Green Optimization: A Must-Have or a Nice-to-Have for the Industry?” workshop
concluded with a renewed commitment to multi-sector collaboration, innovation, and the shared
responsibility of advancing green optimisation through AI, data, and robotics.</p>
<p style={{textAlign:'justify'}}>Thank you to the <a id='hyperlink'  href='https://www.ai4europe.eu/'>AI-on-Demand Platform</a> and  <a  id='hyperlink' href='https://adr-association.eu/'>Adra (the AI-Data-Robotics Association)</a> for co-
hosting the event and fostering such an engaging exchange of ideas. It’s always essential to
witness an organisation like Adra-e multiplying its efforts to build fruitful networking ties between
EU-funded projects. The TUBERS consortium looks forward to continuing its work in advancing
these technologies for a more sustainable and competitive future.</p>
<p style={{textAlign:'justify'}}>Stay tuned for more updates as we continue to innovate and contribute to the green
transformation of industries across Europe!</p>

              



                
                </div>
                
                
                </div>
               
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
  )
}

export default Article14